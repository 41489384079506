<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :enterpriseId="enterpriseId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-enterprise-list', params: { realm: this.$route.params.realmId } }">Enterprises</router-link>
                    <router-link :to="{ name: 'cryptium_id-realm-dashboard', params: { enterpriseId: this.$route.params.enterpriseId, realmId: this.$route.params.realmId } }">Realm</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-toolbar color="indigo" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Realm user
                        </v-toolbar-title>
                        </v-toolbar>
                        <!-- <RealmUserList :list="list" :enterpriseId="enterpriseId" :realmId="realmId"></RealmUserList> -->
                        <RealmUserViewCardText :attr="user" v-if="user"></RealmUserViewCardText>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
// import RequireLogin from '@/components/RequireLogin.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/cryptium_id/RealmBar.vue';
import RealmUserViewCardText from '@/components/realm-dashboard/RealmUserViewCardText.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
        // RequireLogin,
        RealmUserViewCardText,
    },
    data: () => ({
        realm: null,
        user: null,
        error: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            // enterpriseUserList: (state) => state.enterpriseUserList,
        }),
        ...mapGetters({
            // currentEnterprise: 'enterprise',
        }),
        enterpriseId() {
            return this.$route.params.enterpriseId;
        },
        realmId() {
            return this.$route.params.realmId;
        },
        userId() {
            return this.$route.params.userId;
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.enterprise(this.enterpriseId).realm.get({ id: this.realmId });
                console.log(`realm/loginshield.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadUser() {
            try {
                this.$store.commit('loading', { loadUser: true });
                const result = await this.$client.cryptiumIdRealm(this.enterpriseId, this.realmId).realmUser.get({ id: this.userId });
                console.log(`realm/loginshield.vue loadUser result: ${JSON.stringify(result)}`);
                if (result) {
                    this.user = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadUser failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load user', message });
            } finally {
                this.$store.commit('loading', { loadUser: false });
            }
        },
        /*
        async init() {
            const { id } = this.$route.query;
            if (id) {
                const result = await this.$client.user(this.$route.params.realmId).get(id); // NOTE: this is for global users, like admin users themselves, not realm users.
                console.log(`UserView.vue init result: ${JSON.stringify(result)}`);
                if (result) {
                    this.user = result;
                }
            } else {
                this.error = 'bad request';
            }
        },
        */
    },
    mounted() {
        this.loadRealm();
        this.loadUser();
    },
};
</script>
